import React from "react"
import PropTypes from "prop-types"

import ApplicationSelect from "./ApplicationSelect"
import TicketSubject from "./TicketSubject"
import TicketCommentForm from "./TicketCommentForm"
import PrioritySelect from "./PrioritySelect"
import SubmitButton from "./SubmitButton"
import FreeAppRedirect from "./FreeAppRedirect"
import TicketFormContext from "./context/TicketFormContext"

class TicketForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      category: (this.props.form || {}).category || this.props.category,
      app: undefined,
      app_name: undefined,
      database: undefined,
      permission_granted: false,
      run_rate: undefined,
      form_type: (this.props.form || {}).form_type || this.props.form_type,
      app_vip: window.help.const.app_vip,
      permission_granted: false,
      current_macro_placement: "",
      macro_step: 0,
      macro_name: "",
      reading_macro: false,
      blocked_addon: false,
      addon_support_email: undefined,
      macro_dismissed: false
    };
  }

  formTypes () {
    return [
      // 0 - No App, No Addon
      { app: false,       _haddon: false,       _3addon: false,       required_app: false },
      // 1 - Required App, No Addon
      { app: true,        _haddon: false,       _3addon: false,       required_app: true  },
      // 2 - Required App, Heroku Addon
      { app: true,        _haddon: true,        _3addon: false,       required_app: true  },
      // 3 - Required App, 3rd party Addon
      { app: true,        _haddon: false,       _3addon: true,        required_app: true  },
      // 4 - Optional App, No Addon
      { app: true,        _haddon: false,       _3addon: false,       required_app: false }
    ]
  }

  getRunRate = (uuid) => {
    if (!this.props.restrict_node) { return false }
    var _this = this;
    SupportApiRequest({
      url: '/runrate/' + uuid + '/' + window.help.const.user_id,
      success: function(data) {
        _this.setState({run_rate: true});
      },
      error: function(data) {
        _this.setState({run_rate: false});
      }
    })
  }

  updateState = (data) => {
    this.setState(data);
  }

  // A DIY reducer without React Hooks or Redux
  // This method is passed down via Context, and can be used instead of passing handlers down through lots of child components
  dispatch = (action, e = null) => {
    if (e) e.preventDefault();
    switch(action.type) {
      case 'CHANGE_MACRO_PLACEMENT':
        this.setState({ current_macro_placement: action.current_macro_placement });
        break;
      case 'SET_MACRO_NAME':
        this.setState({ macro_name: action.macro_name });
        window.doppler.track('click', { 'click_type': 'Show Macro', 'event': 'show_macro', 'event_location': 'new_ticket_form', 'macro_name': action.macro_name });
        window.dataLayer.push({'event': 'trackEvent', 'eventCat': 'new_ticket_form', 'eventAction': 'show_macro', 'eventLabel': action.macro_name});
        break;
      case 'CHANGE_MACRO_STEP':
        if (this.state.macro_step > action.macro_step) {
          // If they go back, we still want to keep track of how far they read.
          this.setState({ macro_step: action.macro_step, farthest_macro_step: this.state.macro_step })
        } else {
          this.setState({ macro_step: action.macro_step });
        }
        break;
      case 'READING_MACRO':
        this.setState({ reading_macro: true });
        break;
      case 'DONE_READING_MACRO':
        this.setState({ reading_macro: false });
        break;
      case 'DISMISS_MACRO':
        this.setState({ macro_dismissed: true })
        break;
      default:
        new Error('This should not be reached.');
    }
  }

  renderSubForm = (components) => {

    if (this.state.blocked_addon) {

      components.push(
        <div className="flex items-center hk-message--danger mt2" key="blockedAddon">
          <div className="flex-auto">
            <div className="f4 b lh-title pt2">Direct Support Add-on</div>
            <div className="lh-copy">
              <p>This add-on partner provides support for its add-on directly. Heroku Support cannot assist directly with issues related to this add-on, including errors when creating or upgrading the add-on.</p>
              <p>To get help with this add-on, please email <a href={'mailto:' + this.state.addon_support_email}>{this.state.addon_support_email}</a>.</p>
            </div>
          </div>
        </div>
      )
      return components;

    } else {

      // Are we in a restricted node in an app form?
      if (this.props.restrict_node && this.formTypes()[this.state.form_type].app) {
        // do we have a selected app? If not, wait for one.
        if ($('#app').val() === undefined) {
          return components
        }
        // we have a selected app, but do we have any runrate?
        else if (this.state.run_rate === false) {
          components.push(<FreeAppRedirect />)
          return components;
        }
      }

      if (!this.formTypes()[this.state.form_type].app && this.props.restrict_node && this.props.free_user) {
        components.push(<FreeAppRedirect />)
        return components;
      }

      components.push(<TicketSubject        key='subject'
                                            preDefSubject={this.props.form && this.props.form.subject ? this.props.form.subject : ''}/>);

      components.push(<TicketCommentForm    key='comment'
                                            newTicket='true'
                                            showTitle='true'
                                            suggest='true'
                                            showMonitoringModal='true'
                                            label={this.props.form && this.props.form.body_label ? this.props.form.body_label : ''} />);


      if (this.props.allow_vip_priority) {
        components.push(<PrioritySelect     key='priority_select'
                                            form_type={this.state.form_type}
                                            permission_granted={this.state.permission_granted} />);
      }

      components.push(
        <div key='submit' className="flex pv4">
          <div className="w-30 dn flex-l flex-shrink-0 pt1" />
          <div className="flex-auto flex flex-column">
            <SubmitButton value={I18n.t('view.tickets.new.submit')} />
          </div>
        </div>
      );

      return components;
    }

  }



  render () {
    var components = [];
    components.push(<input                type='hidden'
                                          name='ticket[category]'
                                          key='ticket[category]'
                                          value={this.state.category || this.props.breadcrumb.join(' > ')} />)

    if (this.props.appName) {
      components.push(<input              type='hidden'
                                          name='ticket[app_name]'
                                          key='ticket[app_name]'
                                          value={this.props.appName} />)
    }

    if (this.props.form && this.props.form.intro) {
      var intro = {__html: this.props.form.intro}
      components.push(
        <p key='intro' className='lh-copy f4 near-black mv3' dangerouslySetInnerHTML={intro} />
      );
    }

    if (this.formTypes()[this.state.form_type].app) {
      components.push(<ApplicationSelect  key='app_select'
                                          selectDatabase={this.formTypes()[this.state.form_type]._haddon}
                                          selectAddon={this.formTypes()[this.state.form_type]._3addon}
                                          required={this.formTypes()[this.state.form_type].required_app}
                                          getRunRate={this.getRunRate}
                                          updateState={this.updateState}
                                          />)
    }



    return (
      <React.Fragment>
      <TicketFormContext.Provider value={{
        app_name: this.state.app_name,
        current_macro_placement: this.state.current_macro_placement,
        macro_step: this.state.macro_step,
        macro_name: this.state.macro_name,
        reading_macro: this.state.reading_macro,
        macro_dismissed: this.state.macro_dismissed,
        dispatch: this.dispatch}}
      >
        {this.renderSubForm(components)}
        </TicketFormContext.Provider>
      </React.Fragment>
    );
  }
}

export default TicketForm
