import React from "react"
import PropTypes from "prop-types"
import TicketFormContext from "../context/TicketFormContext"
import MacroButtons from "./MacroButtons"
import H12MacroManager from "./H12MacroManager"

class MacroManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_macro: undefined
    }
  }

  static contextType = TicketFormContext;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.text !== prevProps.text) {
      this.findRelatedMacro(this.props.text);
    }
  }

  findRelatedMacro = (text) => {
    let macro = "";
    let h12_regex = new RegExp('H12|H12s|request timeout|response time|slow request|timeout|slow response|timing out|time out|performance issue|slow performance|request queueing|request queuing|requests timingout|request timing out|requests timing out', 'i');

    if (h12_regex.test(text)) {
      macro = <H12MacroManager text={this.props.text} show_if_placement_is={this.props.show_if_placement_is}/>;
      this.context.dispatch({type: 'CHANGE_MACRO_PLACEMENT', current_macro_placement: this.props.show_if_placement_is});
      window.dataLayer.push({'event': 'trackEvent', 'eventCat': 'new_ticket_form', 'eventAction': 'suggest_macro', 'eventLabel': 'H12'});
    }

    if (macro) {
      this.setState({ macro: macro });
    }
  }

  render() {
    return(
        <React.Fragment>
        { this.state.show_macro == undefined && this.context.current_macro_placement == this.props.show_if_placement_is && !this.context.macro_dismissed &&
          <React.Fragment>{ this.state.macro }</React.Fragment>
        }
        </React.Fragment>
    )
  }
}

export default MacroManager
